<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field name="groupon" label="开启团购">
      <template #input>
          <van-switch v-model="row.groupon" size="20" />
      </template>
      </van-field>
      <van-field name="grouponDiscount" label="拼团折扣">
      <template #input>
          <van-stepper v-model="row.grouponDiscount" step="0.01" :decimal-length="2" min="0.5" max="1" />
      </template>
      </van-field>
      <van-field name="grouponDays" label="成团天数">
      <template #input>
          <van-stepper v-model="row.grouponDays" min="1" max="10" />
      </template>
      </van-field>
      <van-field name="grouponParticipants" label="成团人数">
      <template #input>
          <van-stepper v-model="row.grouponParticipants" min="2" max="100" />
      </template>
      </van-field>
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const state = reactive({
      productId: useRoute.query.productId,
      row: {}
    })
    const onSubmit = (values) => {
      console.log('submit', values)
      post('/product.updateGroupon', {
        productId: state.productId,
        ...values
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
      console.log(123)
      post('/product.get', {
        productId: state.productId
      }).then(res => {
        state.row = res.data
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>
